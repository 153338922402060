// quantatec colors

// primary

$light-primary: #2baf4a;
$light-primary-contrast: #ffffff;

$dark-primary: #2baf4a;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #e6d255;
$light-accent-contrast: #212121;

$dark-accent: #e6d255;
$dark-accent-contrast: #212121;

// common

$page-background: #2baf4a2f;
